<template>
  <div>
    <v-card
      :class="['mt-4 pointer', {
        'version-disabled version-disabled-title': !versionEnabled,
        'pinned-version': isPinned,
      }]">
      <v-card-title
        class="d-flex align-start"
        @click="$toggle.open">
        <v-btn
          :class="['header-icon', {
            'version-icon-enabled': versionEnabled,
            'version-icon-disabled': !versionEnabled,
          }]"
          :loading="btnOnOffVersionLoading"
          icon
          fab
          @click.stop="handleOnOff()">
          <v-icon :color="versionEnabled ? 'white':'grey darken-2'">
            power_settings_new
          </v-icon>
        </v-btn>

        <CyButton
          :loading="btnPinLoading"
          variant="tertiary"
          :theme="isPinned ? 'accent' : 'grey'"
          icon-only
          icon="fas fa-thumbtack"
          @click.stop="togglePin"/>

        <div
          class="ml-2 grow">
          <div
            v-for="(value, key, index) in version.version"
            :key="index"
            class="text-left">
            <span>{{ key }}: {{ value }}</span>
          </div>
        </div>
      </v-card-title>
    </v-card>

    <div
      v-if="open"
      :class="['version-detail', {
        'version-disabled version-disabled-title': !versionEnabled,
      }]">
      <v-row>
        <v-col
          cols="12"
          md="4">
          <div class="font-weight-bold">
            inputs to
          </div>
          <v-card
            v-for="(builds, jobName, index) in versionIO.inputs"
            :key="index"
            class="mt-2">
            <v-card-title class="job-name">
              {{ jobName }}
            </v-card-title>
            <v-card-text class="ma-n1">
              <v-btn
                v-for="build in builds"
                :key="build.id"
                :class="`ma-1 build-button build-${build.status} `"
                :to="{ name: 'builds', params: { orgCanonical, projectCanonical, pipelineCanonical: build.pipeline_name, jobCanonical: build.job_name, buildId: build.id } }"
                text
                small>
                #{{ build.name }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4">
          <div class="font-weight-bold">
            outputs of
          </div>
          <v-card
            v-for="(builds, jobName, index) in versionIO.outputs"
            :key="index"
            class="mt-2">
            <v-card-title class="job-name">
              {{ jobName }}
            </v-card-title>
            <v-card-text class="ma-n1">
              <v-btn
                v-for="build in builds"
                :key="build.id"
                :class="`ma-1 build-button build-${build.status}`"
                :to="{ name: 'builds', params: { orgCanonical, projectCanonical, pipelineCanonical: build.pipeline_name, jobCanonical: build.job_name, buildId: build.id } }"
                text
                small>
                #{{ build.name }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4">
          <div class="mb-2 font-weight-bold">
            metadata
          </div>
          <div
            v-for="(metadata, index) in version.metadata"
            :key="index">
            <span class="font-weight-bold">{{ metadata.name }}</span>:
            <a
              v-if="metadata.name.includes('url') && isClickableUrl(metadata.value)"
              :href="$sanitizeUrl(metadata.value)"
              target="_blank"
              rel="noopener noreferrer"
              class="cy-link">
              {{ metadata.value }}
            </a>
            <span v-else>{{ metadata.value }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CyViewsResourceVersion',
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
    componentCanonical: {
      type: String,
      default: '',
    },
    pipelineCanonical: {
      type: String,
      default: '',
    },
    resourceCanonical: {
      type: String,
      default: '',
    },
    resource: {
      type: Object,
      default: () => ({}),
    },
    version: {
      type: Object,
      default: () => ({ enabled: false }),
    },
  },
  data: ({ version }) => ({
    open: false,
    btnOnOffVersionLoading: false,
    btnPinLoading: false,
    versionEnabled: version.enabled,
    outputOf: [],
    inputTo: [],
  }),
  computed: {
    versionIO () {
      const sortedIO = { inputs: {}, outputs: {} }
      for (const inputIdx in this.inputTo) {
        const jobName = this.inputTo[inputIdx].job_name
        if (!sortedIO.inputs.hasOwnProperty(jobName)) {
          sortedIO.inputs[jobName] = []
        }

        // be sure to have unique build name
        if (this.inputTo[inputIdx].name) {
          const nameToFind = this.inputTo[inputIdx].name
          const found = sortedIO.inputs[jobName].some(({ name }) => name === nameToFind)
          if (!found) sortedIO.inputs[jobName].push(this.inputTo[inputIdx])
        }
      }

      for (const outputIdx in this.outputOf) {
        const jobName = this.outputOf[outputIdx].job_name
        if (!sortedIO.outputs.hasOwnProperty(jobName)) {
          sortedIO.outputs[jobName] = []
        }

        // be sure to have unique build name
        const nameToFind = this.outputOf[outputIdx].name
        const found = sortedIO.outputs[jobName].some(({ name }) => name === nameToFind)

        if (!found) sortedIO.outputs[jobName].push(this.outputOf[outputIdx])
      }

      return sortedIO
    },
    isPinned () {
      return _.isEqual(this.resource.pinned_version, this.version.version)
    },
  },
  watch: {
    open (val) {
      if (val) {
        this.getBuildsWithVersionAsInput()
        this.getBuildsWithVersionAsOutput()
      }
    },
  },
  methods: {
    async togglePin () {
      this.btnPinLoading = true
      if (this.isPinned) {
        await this.$cycloid.ydAPI.unpinResource(
          this.orgCanonical,
          this.projectCanonical,
          this.envCanonical,
          this.componentCanonical,
          this.pipelineCanonical,
          this.resourceCanonical,
        )
      } else {
        await this.$cycloid.ydAPI.pinResourceVersion(
          this.orgCanonical,
          this.projectCanonical,
          this.envCanonical,
          this.componentCanonical,
          this.pipelineCanonical,
          this.resourceCanonical,
          this.version.id,
        )
      }
      this.$emit('comment-pinned')
      this.btnPinLoading = false
    },
    async handleOnOff () {
      this.btnOnOffVersionLoading = true
      this.versionEnabled ? await this.disableResourceVersion() : await this.enableResourceVersion()
      this.btnOnOffVersionLoading = false
    },
    async disableResourceVersion () {
      const resBody = await this.$cycloid.ydAPI.disableResourceVersion(
        this.orgCanonical,
        this.projectCanonical,
        this.envCanonical,
        this.componentCanonical,
        this.pipelineCanonical,
        this.resourceCanonical,
        this.version.id,
      )
      if (!_.get(resBody, 'errors')) this.versionEnabled = false
    },
    async enableResourceVersion () {
      const resBody = await this.$cycloid.ydAPI.enableResourceVersion(
        this.orgCanonical,
        this.projectCanonical,
        this.envCanonical,
        this.componentCanonical,
        this.pipelineCanonical,
        this.resourceCanonical,
        this.version.id,
      )
      if (!_.get(resBody, 'errors')) this.versionEnabled = true
    },
    async getBuildsWithVersionAsInput () {
      const resBody = await this.$cycloid.ydAPI.getBuildsWithVersionAsInput(
        this.orgCanonical,
        this.projectCanonical,
        this.envCanonical,
        this.componentCanonical,
        this.pipelineCanonical,
        this.resourceCanonical,
        this.version.id,
      )
      if (resBody && resBody.data) {
        this.inputTo = resBody.data
      }
    },
    async getBuildsWithVersionAsOutput () {
      const resBody = await this.$cycloid.ydAPI.getBuildsWithVersionAsOutput(
        this.orgCanonical,
        this.projectCanonical,
        this.envCanonical,
        this.componentCanonical,
        this.pipelineCanonical,
        this.resourceCanonical,
        this.version.id,
      )
      if (resBody && resBody.data) {
        this.outputOf = resBody.data
      }
    },
    isClickableUrl (url) {
      return url.startsWith('http') && this.$sanitizeUrl(url) !== 'about:blank'
    },
  },
}
</script>

<style lang="scss" scoped>
  .header-icon {
    width: 36px !important;
    height: 36px !important;
    margin-right: 0;
    margin-left: -25px !important;

    .v-icon {
      margin-right: 0;
    }
  }

  .v-card {
    border: 1px solid cy-get-color("primary", $alpha: 0.2);
    border-radius: 5px;
    box-shadow: 2px 3px 8px -6px cy-get-color("primary");
    font-family: $font-family-code;
    font-size: $font-size-sm;
    word-break: break-word;
  }

  .v-card__title {
    padding: 8px;
    font-size: $font-size-sm;
    font-weight: $font-weight-default;
    line-height: 1.5;
  }

  .version-icon-enabled {
    background-color: cy-get-color("build", succeeded);
  }

  .version-icon-disabled {
    background-color: #9e9e9e;
  }

  .version-disabled {
    opacity: 0.5;
  }

  .version-disabled-detail {
    background-color: #bdbdbd !important;
  }

  .version-disabled-title {
    background-color: #e0e0e0 !important;
  }

  .build-button {
    border-radius: 5px;
    color: white;
    font-weight: bold;

    &:hover {
      color: white;
    }
  }

  .build-succeeded {
    background-color: cy-get-color("build", "succeeded");
  }

  .build-running {
    background-color: cy-get-color("build", "running");
  }

  .build-failed {
    background-color: cy-get-color("build", "failed");
  }

  .build-errored {
    background-color: cy-get-color("build", "errored");
  }

  .build-aborted {
    background-color: cy-get-color("build", "aborted");
  }

  .build-pending {
    background-color: cy-get-color("build", "pending");
  }

  .build-paused {
    background-color: cy-get-color("build", "paused");
  }

  .build-started {
    background-color: cy-get-color("build", "running");
  }

  .version-detail {
    padding: 10px 20px 0;
    border-radius: 0 0 5px 5px;
    background-color: rgb(239 239 240);
    font-family: $font-family-code;
    font-size: 12px;
    word-break: break-word;
  }

  .job-name {
    padding: 16px;
    font-size: 12px;
    font-weight: bold;
  }

  .pointer {
    cursor: pointer;
  }

  .pinned-version {
    border-color: cy-get-color("accent") !important;
  }

  .pin-active {
    color: cy-get-color("accent") !important;
  }

  .icon-pin {
    &::before,
    &:active {
      color: transparent;
    }

    &:hover {
      color: cy-get-color("black", $alpha: 0.7);
    }

    width: 16px;
    color: cy-get-color("black", $alpha: 0.5);
  }
</style>
